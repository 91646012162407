
.btn {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 600;
    line-height: 1.25;

    &-collapse {
        border: none;
        background: transparent;

        &--open .btn-collapse__arrow-down {
            transform: rotate(180deg);
        }
    }

    .open {

    }

    &--green {
        color: #30B68F;
    }

    &--default {
      color: #232326;
    }

    &--purple {
      color: #421D57;
    }

    &--lg {
        padding: 12px 0;
        font-size: 18px;
        gap: 18px;
    }

    &--m {
        padding: 8px 0;
        font-size: 16px;
        gap: 16px;
    }

    &--sm {
        padding: 6px 0;
        font-size: 12px;
        gap: 12px;
    }
}
