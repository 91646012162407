
.preloader-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2.5px;
}

.preloader-wrapper .spinner-border {
    color: #30B68F;
}
