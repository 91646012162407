.page, .wrap {
    .scroll {
        max-height: 400px;
    }

    [data-simplebar] {
        overflow: initial !important;
    }

    .simplebar-horizontal, .simplebar-vertical {
        .simplebar-scrollbar.simplebar-visible:before {
            opacity: 1;
            background-color: #5A3171;
        }
    }

    .simplebar-track,
    .s-table .simplebar-track {
        pointer-events: initial;
    }

    .simplebar-track {
        --size: 2px;
        transition: .2s ease;

        &::before {
            content: '';
            z-index: -1;
            display: block;
            position: absolute;
            background: rgba(90, 49, 113, 0.2);
            border-radius: calc(var(--size) * 0.5);
            cursor: pointer;
        }

        .simplebar-scrollbar {
            &::before {
                cursor: grab;
            }
        }

        &.simplebar-horizontal {
            height: 10px;

            &::before {
                left: 2px;
                right: 2px;
                height: var(--size);
                top: calc(50% - (var(--size) * 0.5));
            }

            .simplebar-scrollbar {
                top: 0;
                height: 10px;

                &::before {
                    left: 0;
                    right: 0;
                    top: calc(50% - (var(--size) * 0.5));
                    height: var(--size);
                }
            }
        }

        &.simplebar-vertical {
            width: 10px;

            &::before {
                top: 2px;
                bottom: 2px;
                left: calc(50% - (var(--size) * 0.5));
                width: var(--size);
            }

            .simplebar-scrollbar {
                left: 0;
                width: 10px;

                &::before {
                    top: 0;
                    bottom: 0;
                    left: calc(50% - (var(--size) * 0.5));
                    width: var(--size);
                }
            }
        }
    }

    .simplebar-track:hover,
    .simplebar-dragging .simplebar-track {
        --size: 4px;
    }
}

.dropdown {
    .simplebar-content:after, .simplebar-content:before {
        display: none;
    }

    .simplebar-track.simplebar-horizontal {
        bottom: -5px;
    }

    .simplebar-track.simplebar-vertical {
        right: -5px;
    }
}
