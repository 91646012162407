$component: '.checkbox';

#{$component} {
    display: block;
    max-width: 100%;
    margin: 0;

    &:not(#{$component}_disabled) {
        cursor: pointer;
    }

    &_inline {
        display: inline-block;
    }

    &__input {
        display: none;

        &:checked ~ #{$component}__label {
            &::before {
                background: url(~@img/icon/green-checkbox.svg) center no-repeat;
                background-size: contain;
                border: none;
            }
        }
    }

    &__label {
        position: relative;
        display: block;
        padding-left: 28px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 100%;
        letter-spacing: 0.22px;
        font-family: Inter, Roboto, Roboto Slab, sans-serif;
        font-size: 15px;
        line-height: 18px;
        min-height: 18px;
        //font-weight: 400;
        color: #414042;

        &::before {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            display: block;
            width: 18px;
            height: 18px;
            border-radius: 3px;
            border: 2px solid #30b68f;
        }
    }
}
