.reactive-alert {
  --vartical-space: 15px;
}
.reactive-alert .modal-body {
  text-align: center;
  padding: 45px;
}
.reactive-alert .modal-body__title {
  margin: 0 0 1rem;
  font-family: "Roboto Slab", sans-serif;
  font-weight: 700;
  font-size: 20px;
  line-height: 26px;
  color: #421D57;
}
.reactive-alert .modal-body__title + .reactive-alert .modal-body__message:not(:empty), .reactive-alert .modal-body__message + .modal-body__actions:not(:empty) {
  margin-top: var(--vartical-space);
}
.reactive-alert_success .modal-body__title {
  color: #30B68F;
}
.reactive-alert_danger .modal-body__title {
  color: red;
}
@media (min-width: 576px) {
  .reactive-alert .modal-dialog {
    max-width: 560px;
  }
}
@media (min-width: 1600px) {
  .reactive-alert {
    --vartical-space: 30px;
  }
  .reactive-alert .modal-body__title {
    font-size: 36px;
    line-height: 47px;
  }
  .reactive-alert .modal-body__message {
    font-size: 18px;
  }
}