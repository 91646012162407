.reactive-confirm {
    .modal-body {
        text-align: center;
        padding: 45px;

        &__title {
            margin: 0 0 15px;
            font-family: 'Roboto Slab', sans-serif;
            font-weight: 700;
            font-size: 20px;
            line-height: 26px;
            color: #421D57;
        }

        &__message + .modal-body__actions:not(:empty) {
            margin-top: 15px;
        }
    }

    @media (min-width: 576px) {
        .modal-dialog {
            max-width: 560px;
        }
    }

    @media (min-width: 1600px) {
        .modal-body {
            &__title {
                font-size: 30px;
                line-height: 1.1;
                margin-bottom: 30px;
            }

            &__message {
                font-size: 18px;
            }

            &__message + .modal-body__actions:not(:empty) {
                margin-top: 30px;
            }
        }
    }
}
