.radio-list {
    // reset ul.
    &__list {
        list-style: none;
        padding-left: 0;
        margin-bottom: 0;
    }

    &__item {
        display: block;
        margin: 0 0 10px;

        label {
            font-weight: normal;
        }
    }
}
