.head-bl {
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 32px;
  color: #421D57;
  letter-spacing: 0.22px;
}

.modal-sticky-top {
  padding-top: 5px;
  background: linear-gradient(135.37deg, rgba(0, 0, 0, 0.4) 4.29%, rgba(255, 255, 255, 0.4) 95.6%), #EBECF0;
  background-blend-mode: soft-light, normal;
}

.full-modal {
  padding: 0 !important;
}

.full-modal .modal-dialog {
  width: 100%;
  max-width: none;
  height: 100%;
  margin: 0;
}

.full-modal .modal-content {
  height: 100%;
  border: 0;
  border-radius: 0;
}

.full-modal .modal-body {
  overflow-y: auto;
}

.full-modal .flex-center {
  min-height: 430px;
  padding-top: 15px;
  margin: auto;
  overflow: auto;
  width: 95%;
}

.full-modal .modal-body {
  padding-top: 0;
}