.reactive-confirm .modal-body {
  text-align: center;
  padding: 45px;
}
.reactive-confirm .modal-body__title {
  margin: 0 0 15px;
  font-family: "Roboto Slab", sans-serif;
  font-weight: 700;
  font-size: 20px;
  line-height: 26px;
  color: #421D57;
}
.reactive-confirm .modal-body__message + .modal-body__actions:not(:empty) {
  margin-top: 15px;
}
@media (min-width: 576px) {
  .reactive-confirm .modal-dialog {
    max-width: 560px;
  }
}
@media (min-width: 1600px) {
  .reactive-confirm .modal-body__title {
    font-size: 30px;
    line-height: 1.1;
    margin-bottom: 30px;
  }
  .reactive-confirm .modal-body__message {
    font-size: 18px;
  }
  .reactive-confirm .modal-body__message + .modal-body__actions:not(:empty) {
    margin-top: 30px;
  }
}