.checkbox-list {
    // reset ul.
    list-style: none;
    padding-left: 0;
    margin-bottom: 0;

    &_flex {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }

    &__item {
        display: block;
        margin: 0 0 10px;
    }
}
