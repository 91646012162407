
.modal-dialog.modal-custom {
  min-width: unset;
  max-width: unset;
}

.modal-body,
.modal-body__default {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.modal-body {
  overflow: initial
}

.modal.modal--scroll.show,
.modal.show {
  overflow: auto;
}
