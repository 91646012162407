.dropdown {
    &__handler {
        cursor: pointer;
    }

    &_active {
        .placeholder {
            background: linear-gradient(317.7deg, rgba(0, 0, 0, 0.4) 0, #30b68f 105.18%), #ebecf0;
            background-blend-mode: soft-light, normal;
        }
    }

    &__body {
        z-index: 15;
        position: absolute;
        left: 0;
        min-width: 100%;
        top: calc(100% + 2px);
        max-width: Min(650px, 100vw);
        padding: 15px;
        background: linear-gradient(317.7deg, rgba(0, 0, 0, .4) 0, rgba(255, 255, 255, .4) 105.18%), #ebecf0;
        background-blend-mode: soft-light, normal;
        box-shadow: 4px 4px 4px rgba(0, 0, 0, .25), inset -5px -5px 10px #fafbff, inset 5px 5px 10px #a6abbd;
        border: 1px solid rgba(255, 255, 255, .4);
        border-radius: 20px;

        &_right {
            left: auto;
            right: 0;
        }
    }

    &_right &__body {
        left: auto;
        right: 0;
    }

    &_top &__body {
        top: auto;
        bottom: calc(100% + 2px);
    }

    &__actions {
        display: flex;

        > a,
        > [role="button"],
        > button {
            margin-right: 4px;
        }
    }

    .checkbox-list__item:last-child,
    .radio-list__item:last-child {
        margin-bottom: 0;
    }

    .icon_delete {
        z-index: 100;
        position: absolute;
        right: 30px;
        top: calc(50% - (var(--size) / 2));
    }

    .icon_lens {
        position: absolute;
        right: 12px;
        top: 50%;
        transform: translateY(-50%);
        border: none;
        outline: 0
    }
}
