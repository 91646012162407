@import "@common/scss/abstracts/_abstracts.scss";

.reactive-alert {
    --vartical-space: 15px;

    .modal-body {
        text-align: center;
        padding: 45px;

        &__title {
            margin: 0 0 1rem;
            font-family: 'Roboto Slab', sans-serif;
            font-weight: 700;
            font-size: 20px;
            line-height: 26px;
            color: #421D57;
        }

        &__title + &__message:not(:empty),
        &__message + .modal-body__actions:not(:empty) {
            margin-top: var(--vartical-space);
        }
    }

    &_success {
        .modal-body {
            &__title {
                color: $green;
            }
        }
    }

    &_danger {
        .modal-body {
            &__title {
                color: red;
            }
        }
    }

    @media (min-width: 576px) {
        .modal-dialog {
            max-width: 560px;
        }
    }

    @media (min-width: 1600px) {
        --vartical-space: 30px;

        .modal-body {
            &__title {
                font-size: 36px;
                line-height: 47px;
            }

            &__message {
                font-size: 18px;
            }
        }
    }
}
