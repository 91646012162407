button.icon {
  appearance: none;
  border: 0;
  background: transparent;
}

.icon {
  --size: 16px;
  display: inline-block;
  width: var(--size);
  height: var(--size);
  text-indent: -9999px;
  font-size: 0;
  cursor: pointer;
}
.icon_color_green {
  color: #30B68F;
}
.icon_color_purple {
  color: #421D57;
}
.icon_color_default {
  color: #232326;
}
.icon_flip {
  transform: rotate(180deg);
}

.icon-text, .text-icon {
  display: inline-flex;
  align-items: center;
}

a.icon-text, a.text-icon {
  cursor: pointer !important;
  text-decoration: none !important;
}

.icon-text .icon {
  margin-right: 6px;
}

.text-icon .icon {
  margin-left: 6px;
}