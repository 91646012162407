.btn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 600;
  line-height: 1.25;
}
.btn-collapse {
  border: none;
  background: transparent;
}
.btn-collapse--open .btn-collapse__arrow-down {
  transform: rotate(180deg);
}
.btn--green {
  color: #30B68F;
}
.btn--default {
  color: #232326;
}
.btn--purple {
  color: #421D57;
}
.btn--lg {
  padding: 12px 0;
  font-size: 18px;
  gap: 18px;
}
.btn--m {
  padding: 8px 0;
  font-size: 16px;
  gap: 16px;
}
.btn--sm {
  padding: 6px 0;
  font-size: 12px;
  gap: 12px;
}