.radio {
    &_custom {
        display: block;
        cursor: pointer;
        font-family: Inter, Roboto, Roboto Slab, sans-serif;
        font-size: 15px;
        line-height: 18px;
        white-space: nowrap;
        margin: 0;

        input {
            display: none !important
        }

        > .radio__label {
            display: block;
            padding-left: 28px;
            position: relative;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 100%;
            min-height: 18px;

            &::before {
                content: '';
                display: block;
                width: 18px;
                height: 18px;
                border-radius: 50%;
                border: 1px solid #421d57;
                position: absolute;
                left: 0;
                top: 0;
            }
        }

        input:checked + .radio__label:after {
            content: '';
            display: block;
            width: 12px;
            height: 12px;
            border-radius: 50%;
            position: absolute;
            left: 3px;
            top: 3px;
            background: #30b68f;
        }

        .radio__label-postfix {
            font-weight: 400;
            font-size: 12px;
        }
    }
}
